<template>
  <div class="col-12 fz20 pt-3">
    <span v-if="!eniStarting && !eniStarted">Il tuo codice è stato inserito correttamente. Eni gas e luce ti ricorda che, per guardare in streaming il concerto di Gianna Nannini, dovrai tornare su questa pagina il 3 Settembre alle 21:00</span>
    <span v-if="eniStarting && !eniStarted">{{ $t('eniStarting') }}</span>
    <span v-if="eniStarted && !eniCompleted">{{ $t('eniStarted') }}</span>
    <span v-if="eniCompleted">{{ $t('eniCompleted') }}</span>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EniActivated',
  data() {
    return {
      eniStarting: false,
      eniStarted: false,
      eniCompleted: false,
      ta: false,
    };
  },
  methods: {
    getStatus() {
      const self = this;
      const v = new Date().getTime();
      axios.get(`./eni.json?v=${v}`)
        .then(({ data }) => {
          console.log(data);
          self.eniStarting = data.eniStarting;
          self.eniStarted = data.eniStarted;
          self.eniCompleted = data.eniCompleted;
          self.ta = setTimeout(() => {
            self.getStatus();
          }, 5000);
        })
        .catch(() => {
          clearTimeout(self.ta);
        });
    },
  },
  mounted() {
    this.getStatus();
  },
};
</script>

<style scoped>

</style>
