<template>
  <div class="eni-status">
    <EniActivated />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import EniActivated from './EniActivated.vue';

export default {
  name: 'EniStatus',
  components: { EniActivated },
  methods: {
    ...mapMutations(['appReady']),
  },
  mounted() {
    this.appReady(true);
  },
};
</script>

<style scoped>
  .eni-status {
    padding: 30px;
    color: white;
    text-align: center;
  }
</style>
